<template>
  <div>
    <div class="pc">
      <template>
        <!-- <el-carousel :interval="3000" arrow="always">
          <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <div v-if="item.allowImgLink" class="bannag">
              <a :href="item.imgJumpUrl"><img :src="domainUrl+item.bannerUrl" alt=""></a> -->
              <!-- 按钮 -->
              <!-- <a class="img-more" v-if="item.allowJump" :href="item.jumpUrl" target="_blank">
                {{item.btnValue}}
              </a>
            </div>
            <div v-else class="bannag">
              <img :src="domainUrl+item.bannerUrl" alt=""> -->
              <!-- 按钮 -->
              <!-- <a class="img-more" v-if="item.allowJump" :href="item.jumpUrl" target="_blank">
                {{item.btnValue}}
              </a>
            </div>
          </el-carousel-item>
        </el-carousel> -->
        <t-carousel :list.sync="bannerList" :time-interval="6000" ref="carousel">
          <template #default="{data, nowIndex}">
            <div class="banner-item" v-for="(item,index) in data" :key="index" :style="`--index: ${item.step}; opacity: ${index === (nowIndex % data.length) ? 1 : 0.3};`">
              <div v-if="item.allowImgLink" class="bannag">
                <a :href="item.imgJumpUrl"><img :src="domainUrl+item.bannerUrl" alt=""></a>

                <a class="img-more" v-if="item.allowJump" :href="item.jumpUrl" target="_blank">
                  {{item.btnValue}}
                </a>
              </div>
              <div v-else class="bannag">
                <img :src="domainUrl+item.bannerUrl" alt="">

                <a class="img-more" v-if="item.allowJump" :href="item.jumpUrl" target="_blank">
                  {{item.btnValue}}
                </a>
              </div>
            </div>
          </template>
        </t-carousel>
      </template>
      <div class="banxin">
        <div class="conter-biao">{{$i18n.t('home.whatIsNew')}}</div>
        <div class="banxin-top">
          <a class="ing-shai" v-for="(item,index) in whatsNewList" :key="index" :href="item.url" target="_blank">
            <img :src="item.image" alt="">
            <div class="ing-shai-di">
              <span>{{item.title}}</span>
              <h3>{{ item.summary }}</h3>
            </div>
          </a>
        </div>
      </div>
      <div class="banxin-img">
        <div class="conter-biao">{{$i18n.t('home.ProductSpotlight')}}</div>
        <div class="swiper-bb">
          <div class="swiper swiper-container" ref="swiper">
            <div class="swiper-wrapper banxin-img-top">
              <a class="swiper-slide" v-for="(item,index) in ProductList" :key="index" :href="item.jumpUrl" target="_blank">
                <div class="cont-populra">
                  <div>
                    <img class="imgssss" :src="item.imageUrl" alt="">
                    <div class="boxxx-img" v-if="item.isNew"><img src="../../assets/image/home/new_icon.png"></div>
                  </div>
                  <div class="boxxx-span">
                    <h4>{{item.title}}</h4>
                    <span>{{item.summary}}</span>
                  </div>
                </div>
              </a>
            </div>
            <div class="swiper-button-next" @click="prev"></div>
            <div class="swiper-button-prev" @click="next"></div>
          </div>
        </div>
        <div class="banxin-img_conter">
          <span class="arrow">></span>
          <a href="/product-center" target="_blank">{{$i18n.t('home.learnMore')}}</a>
        </div>
      </div>
      <div class="banxin">
        <div class="conter-biao">{{$i18n.t('home.Events&News')}}</div>
        <div class="banxin-New">
          <a class="banxin-New-con" v-for="(item,index) in eventsList" :key="index" :href="item.detail" target="_blank">
            <div class="boxxx-img"><img src="../../assets/image/home/Group 61.png" alt=""></div>
            <div class="news-img">
              <img :src="item.image" alt="">
            </div>
            <div class="banxin-New-con_bottun">
              <h3>{{item.title}}</h3>
              <p>{{item.summary}}</p>
              <div class="banxin-New-bout">
                <span>{{item.publishDate}}</span>
              </div>
            </div>
          </a>
          <div class="banxin-New-con-h3">
            <div class="boxxx-img">
              <img src="../../assets/image/home/Group 22.png" alt="">
            </div>
            <div v-for="(item,index) in newsList" :key="index" >
              <a :href="item.detail" target="_blank">{{item.title}}</a>
              <span>{{item.publishDate}}</span>
            </div>
          </div>
        </div>
        <div class="banxin-img_conter banxin-button">
          <span class="arrow">></span>
          <a href="/news" target="_blank">{{$i18n.t('home.learnMore')}}</a>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="yido">
      <!-- 轮播 -->
      <div class="bannags">
        <template>
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item v-for="(item,index) in appBannerList" :key="index">
              <div>
                <img :src="domainUrl+item.bannerUrl" alt="">
                <!-- 按钮 -->
                <a class="img-more" v-if="item.allowJump" :href="item.jumpUrl">
                  {{$i18n.t('home.learnMore')}}
                </a>
              </div>
            </el-carousel-item>
          </el-carousel>
        </template>
      </div>
      <!-- What's New -->
      <div class="box-new-container">
        <div class="title">{{$i18n.t('home.whatIsNew')}}</div>
        <a class="box_New" v-for="(item,index) in whatsNewList" :key="index" :href="item.url">
          <img class="box_New_img" :src="item.image" alt="">
          <div class="box_New_button">
            <p>{{item.title}}</p>
            <p>{{ item.summary }}</p>
          </div>
        </a>
      </div>
      <!-- Product Spotlight -->
      <div class="title">{{$i18n.t('home.ProductSpotlight')}}</div>
      <div class="product-Spotlight-box">
        <a class="Spotlight-box" v-for="(item,index) in ProductList" :key="index" :href="item.jumpUrl">
          <div class="conten">
            <img class="img" :src="item.imageUrl" alt="">
          </div>
          <div class="content">
            <p>{{item.title}}</p>
            <p>{{ item.summary }}</p>
          </div>
        </a>
      </div>
      <!-- 了解更多 -->
      <div class="learn-more">
        <img src="../../assets/image/home/Group 35.png" alt="">
        <a href="/product-center">{{$i18n.t('home.learnMore')}}</a>
      </div>
      <!-- Events & News -->
      <div class="title">{{$i18n.t('home.Events&News')}}</div>
      <div class="box-events-news">
        <a class="events-news" v-for="(item,index) in newsList" :key="index" :href="item.detail">
          <img class="img" :src="item.image" alt="">
          <div class="content">
            <p>{{item.title}}</p>
            <p>{{item.summary}}</p>
            <p>{{item.publishDate}}</p>
          </div>
        </a>
      </div>
      <div class="learn-more">
        <img src="../../assets/image/home/Group 35.png" alt="">
        <a href="/news">{{$i18n.t('home.learnMore')}}</a>
      </div>
      <!-- Follow Us -->
      <div class="title">Follow Us</div>
    </div>
  </div>
</template>
<script>
import Swiper,{Navigation} from 'swiper'
import 'swiper/swiper-bundle.css';
Swiper.use([Navigation])
import {listBannerByPosition} from "@/api/bannerApi";
import { pageWith } from '@/api/news';
import TCarousel from '@/views/common/carousel/TCarousel.vue';
export default {
  name: 'home',
  components: {
    't-carousel': TCarousel
  },
  data () {
    return {
      bannerList: [],
      appBannerList: [],
      ProductList: [
        {
          title: "P1-5R(2.8-12)A AI",
          summary: "1T 5MP AI IR Bullet Camera",
          imageUrl: "https://resources.holowits.com/product/p1cover.png",
          isNew: false,
          jumpUrl:"/product-center/detail/AIC/03363WAM",
        },
        {
          title: "P4-5R(2.8-12)A AI",
          summary: "1T 5MP AI IR Fixed Dome Camera",
          imageUrl: "https://resources.holowits.com/product/p4.png",
          isNew: false,
          jumpUrl:"/product-center/detail/AIC/03365WAM",
        },
        {
          title: "P5-5LRV(33x)A AI",
          summary: "1T 5MP IR/White-Light AI PTZ Dome Camera with Intercom",
          imageUrl: "https://resources.holowits.com/product/p5.png",
          isNew: false,
          jumpUrl:"/product-center/detail/AIC/03366WAM",
        },
        {
          title: "U1-4LRV(4)(8-32)A AI",
          summary: "2T 4MP Dual-Lens AI Bullet Camera",
          imageUrl: "https://resources.holowits.com/product/u1.png",
          isNew: false,
          jumpUrl:"/product-center/detail/AIC/03367WAM",
        },
      ],
      whatsNewList:[
        {
          title:"Branding",
          summary:"Everything Sensing for an Intelligent World",
          url:"https://resources.holowits.com/material/HOLOWITS Brand Video-Everything Sensing for an Intelligent World New LOGO.mp4",
          image:require("@/assets/image/home/Frame 18.png"),
        },
        {
          title: "Branding",
          summary: "",
          url: "https://www.youtube.com/watch?v=rn25P_qHVwU&t=5s",
          image: require("@/assets/image/home/cover.jpg"),
        },
        {
          title: "",
          summary: "",
          url: "https://holowits.com/show/",
          image: require("@/assets/image/home/450-318.jpg"),
        },
        {
          title:"Resource",
          summary:"HOLOWITS Product Portfolio 2024",
          url:"https://resources.holowits.com/material/[Product%20Portfolio]HOLOWITS%20Product%20Portfolio%202024-Preview.pdf",
          image:"https://resources.holowits.com/home/HOLOWITS_Product_Portfolio_2024.png",
        },
        {
          title:"Product",
          summary:"What Is a Software-Defined Camera?",
          url:"/what-is-a-software-defined-camera",
          image:require("@/assets/image/home/Frame 22.png"),
        },
      ],
      eventsList:[],
      newsList: []
    }
  },
  created() {
    this.init()
    this.newsQuery()
  },
  mounted () {
    this.$nextTick(() => {
      this.myswiper()
    })
  },
  updated() {
    setTimeout(() => {
      this.$refs['carousel'].init();
    }, 500)
  },
  methods: {
    init(){
      listBannerByPosition({showPosition:"home"}).then(response=>{
        this.bannerList = response.data.data;
        setTimeout(() => {
          this.$refs['carousel'].init();
        }, 500)
      })
      listBannerByPosition({showPosition:"home-app"}).then(response=>{
        this.appBannerList = response.data.data
      })
    },
    prev () {
    },
    next () {
    },
    myswiper() {
      let mySwiper = new Swiper('.swiper', {
        loop: true, // 循环模式选项
        autoplay:true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 30,
        on: {
          resize() {
            console.log(1)
            mySwiper.update()
          }
        }
      })
      setInterval(() => { mySwiper.slideNext() }, 3500)
    },
    async newsQuery() { // 分页查询新闻
      let queryParams = {
        current: 1,
        model: {
          "startDate": "",
          "endDate": "",
          "type": "",
          "status": "Online",
          "title": ""
        },
        size: 10,
        sort: 'id'
      }
      const {data: {code, data: {records}, message, isSuccess}} = await pageWith(queryParams)
      if (isSuccess) {
        let eventsList = []
        let newsList = []
        for (let i of records) {
          if (i['type'] === 'News') {
            newsList.push({'title': i.title, 'summary': i.summary, 'image': `https://resources.holowits.com/${i.imgSrc}`, 'detail': `/news/${i.id}/detail`, 'publishDate': i.publishDate})
          } else {
            eventsList.push({'title': i.title, 'summary': i.summary, 'image': `https://resources.holowits.com/${i.imgSrc}`, 'detail': `/news/${i.id}/detail`, 'publishDate': i.publishDate})
          }
        }
        if (!eventsList.length) {
          this.eventsList = [newsList[0], newsList[1]]
        } else {
          this.eventsList = eventsList
        }
        this.newsList = newsList
      }
    }
  }
};
</script>
<style scoped lang="less">
@media (min-width: 991px) {
  .img-more {
    width: 250/19.2vw !important;
    height: 82/19.2vw !important;
    border-radius: 54/19.2vw;
    font-size: 28/19.2vw !important;
    font-weight: normal !important;
    text-align: center;
    line-height: 82/19.2vw;
    cursor: pointer;
    background-color: #c7000b;
    color: #fff;
    position: absolute;
    bottom: 94/19.2vw;
    left: 50%;
    margin-left: -125/19.2vw;
  }
  /deep/ .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: -37px;
    right: auto;
  }
  .swiper-bb {
    width: 1450px;
    margin: 0 auto;
    position: relative;
  }
  /deep/ .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: -37px;
    left: auto;
  }
  /deep/ .swiper-button-prev,
  .swiper-button-next {
    color: #999999;
    opacity: 0.8;
  }
  /deep/ .swiper {
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    position: unset;
  }
  /deep/ .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 30px;
  }
  /deep/ .swiper-button-next:hover {
    color: #666;
  }
  /deep/ .swiper-button-prev:hover {
    color: #666;
  }

  .yido {
    display: none;
  }
  .el-carousel__item {
    height: 680/19.2vw;
    max-height: 680/19.2vw;
    height: 100%;
    max-width: 1920px;
    margin: auto;
  }
  /deep/ .el-carousel__container {
    width: 100%;
    max-height: 680/19.2vw;
    height: 680/19.2vw;
    max-width: 1920px;
    margin: auto;
  }
  .bannag {
    width: 100%;
    height: 100%;
    max-width: 1920px;
    margin: auto;
    img {
      height: 100%;
      width: 100%;
      max-width: 1920px;
      margin: auto;
    }
  }
  .banxin {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    .banxin-top {
      width: 1410px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .banxin-img_conter {
    width: 100%;
    text-align: center;
    margin: 44px auto;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 19px;
    transition: all 0.5s;
    .arrow {
      display: inline-block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      background: #f0f0f0;
      color: #c7000b;
      border-radius: 50%;
      transition: all 0.5s;
      margin-right: 10px;
      font-size: 16px;
    }
    a {
      cursor: pointer;
      color: #000000;
    }
    &:hover .arrow {
      color: #c7000b;
    }
    a:hover {
      color: #c7000b;
    }
    img {
      margin-right: 10px;
    }
  }
  .banxin-New-bout {
    display: flex;
    justify-content: space-between;
    .banxin-New-bout-span {
      display: flex;
      cursor: pointer;
    }
  }
  .conter-biao {
    width: 100%;
    margin: 120px 0 50px 0;
    text-align: center;
    color: #000000;
    font-size: 40px;
    font-weight: bold;
  }
  .ing-shai {
    img {
      transition: all 1s;
    }
  }
  .ing-shai:hover img {
    transform: scale(1.1);
    //   /* 变形：缩放，放大比例(1.1) */
    // transition: 1s;
    /* 溢出隐藏 */
  }

  .ing-shai {
    cursor: pointer;
    position: relative;
    margin-top: 30px;
    border-radius: 10px;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      left: 0;
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.3) 70%);
      display: none;
      z-index: 1;
    }
    &:hover::before {
      display: block;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .ing-shai-di {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      z-index: 33;
      height: 140px;
      span {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.8);
        font-weight: 400;
      }
      h3 {
        margin-top: 10px !important;
        font-size: 20px;
        color: #fff;
        font-weight: bold;
        line-height: 28px;
      }
    }
  }

  .banxin-img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .banxin-img-top {
    width: 1410px;
    margin: 0 auto;
    display: flex;
  }

  .banxin-img-top {
    padding: 10px;
  }

  .cont-populra {
    user-select: none;
    border-radius: 8px;
    // transform: scaleY(-1);
    cursor: pointer;
    width: 330px !important;
    height: 446px !important;
    background: #ffffff;
    box-shadow: 0px 4px 12px 5px rgba(219, 219, 219, 0.25);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    position: relative;
    &:hover {
      transition: all 1s;
      box-shadow: 0px 0px 16px 1px rgba(139, 139, 139, 0.25);
    }

    .tip {
      font-size: 22px;
      font-weight: bold;
      color: #c7000b;
      line-height: 33px;
      position: absolute;
      top: 18px;
      left: 18px;
    }
    .imgssss {
      width: 100% !important;
      height: 300px !important;
      object-fit: contain;
    }
    div:nth-of-type(1) {
      width: 100%;
      height: 300px;
      position: relative;
      > img {
        width: 100%;
        height: 100%;
      }
      .boxxx-img {
        width: 74px;
        height: 40px;
        background: rgba(240, 240, 240, 0.5);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
      }
      .boxx-img {
        width: 158px;
        height: 40px;
        background: #c7000b;
        border-radius: 50px 50px 50px 50px;
        opacity: 0;
        padding: 8px 22px;
        position: absolute;
        bottom: 22px;
        right: 16px;
        span {
          width: 88px;
          height: 24px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
        }
        img {
          width: 18px;
          height: 18px;
          margin-top: 4px;
          opacity: 1;
          float: right;
        }
      }
    }
    .boxxx-span {
      // width: 430px;
      height: 148px;
      background: #f7f7f7;
      border-radius: 8px;
      opacity: 1;
      padding: 22px;
      text-align: left;
      h4 {
        font-size: 18px;
        font-weight: 600;
        color: #000000;
        line-height: 20px;
      }
      span {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 33px;
      }
    }
  }
  // .cont-populra:hover div:nth-of-type(1) > .boxx-img {
  //   transition: all 1s;
  //   opacity: 1;
  // }
  .banxin-New {
    width: 1410px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .banxin-New-con {
      position: relative;
      width: 450px;
      height: 610px;
      background: #f7f7f7;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      cursor: pointer;
      .news-img {
        overflow: hidden;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        img {
          width: 100%;
          object-fit: contain;
          transition: all 1s;
        }
      }
      &:hover .news-img {
        img {
          // transition: all 1s;
          transform: scale(1.2);
        }
      }
      .banxin-New-con_bottun {
        padding: 0 30px;
      }
      .boxxx-img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
      }
      h3 {
        width: 391px;
        height: 87px;
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        line-height: 29px;
        margin: 0 auto;
        margin-top: 30px;
      }
      p {
        width: 391px;
        height: 115px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 23px;
        margin: 0 auto;
        margin-top: 30px;
      }
      span {
        display: inline-block;
        width: 78px;
        height: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 16px;
        margin-top: 30px;
      }
    }
  }
  .banxin-New-con-h3::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .banxin-New-con-h3::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    width: 2px;
    height: 99px;
    background: #cbcaca;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
  .banxin-New-con-h3::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    // background: #ededed;
    // border-radius: 10px;
  }

  .banxin-New-con-h3 {
    position: relative;
    width: 450px;
    height: 610px;
    background: #f7f7f7;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    overflow-y: auto;
    div:nth-of-type(1) {
      margin-top: 0;
    }
    div {
      margin-top: 30px;
      a {
        cursor: pointer;
        width: 390px;
        height: 58px;
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        line-height: 29px;
        margin: 0 auto;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        &:hover {
          color: #c7000b;
        }
      }
      span {
        display: inline-block;
        width: 78px;
        height: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 16px;
        margin-left: 30px;
      }
    }
  }
  .banxin-button {
    margin: 60px auto;
    cursor: pointer;
    &:hover {
      color: #c7000b;
    }

    button {
      width: 178px;
      height: 40px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      border: 2px solid #333333;
    }
    button:hover {
      width: 178px;
      height: 40px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      border: 2px solid #c7000b;
    }
  }

  .swiper {
    height: 100%;
    width: 1410px;
  }

  /deep/ .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 991px) {
  .yido {
    background: #f1f1f1;
  }
  .img-more {
    width: 224/7.5vw !important;
    height: 72/7.5vw !important;
    border-radius: 54/7.5vw;
    font-size: 24/7.5vw !important;
    font-weight: normal !important;
    text-align: center;
    line-height: 72/7.5vw;
    cursor: pointer;
    background-color: #c7000b;
    color: #fff;
    position: absolute;
    bottom: 180/7.5vw;
    left: 50%;
    margin-left: -112/7.5vw;
  }
  .pc {
    display: none;
  }
  .bannags {
    width: 100%;
    height: 90vw;
    .el-carousel {
      height: 100%;
    }
    /deep/ .el-carousel__container {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  // 公用样式
  .title {
    padding: 48/7.5vw 0 29/7.5vw 29/7.5vw;
    font-size: 34/7.5vw;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 40/7.5vw;
  }
  .learn-more {
    text-align: center;
    margin-top: 30/7.5vw;
    font-size: 24/7.5vw;

    font-weight: 400;
    color: #666666;
    line-height: 39/7.5vw;
    img {
      width: 5.4vw;
      height: 5.4vw;
      margin-right: 10/7.5vw;
    }
  }
  // 模块样式
  .box-new-container {
    .box_New {
      display: inline-block;
      width: calc(100% - 7.64vw);
      margin: 0 3.73vw 2vw;
      height: 53.33vw;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
      .box_New_img {
        width: 100%;
      }
      .box_New_button {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 20/7.5vw;
        position: absolute;
        bottom: 0;
        left: 0;
        color: #fff;
        box-sizing: border-box;
        p:nth-child(1) {
          font-size: 24/7.5vw;

          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
          line-height: 28px/7.5vw;
        }
        p:nth-child(2) {
          font-weight: bold;
          font-size: 34/7.5vw;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 54/7.5vw;
          margin-top: 9px;
        }
      }
    }
  }
  .product-Spotlight-box {
    padding: 3.73vw;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .Spotlight-box {
      width: 50%;
      padding: 2vw;
      box-sizing: border-box;
      overflow: hidden;
      border-radius: 8/7.5vw !important;
      .conten {
        background: #fff;
      }
      .img {
        width: 100%;
        height: 44.8vw;
      }
      .content {
        background-color: #f7f7f7;
        padding: 4vw 2vw;
        p:nth-child(1) {
          margin-bottom: 10/7.5vw;
          font-size: 26/7.5vw;

          font-weight: 400;
          color: #333333;
          line-height: 40/7.5vw;
        }
        p:nth-child(2) {
          font-size: 22/7.5vw;

          font-weight: 400;
          color: #666666;
          line-height: 33/7.5vw;
        }
      }
    }
  }
  .box-events-news {
    .events-news {
      margin-bottom: 2vw;
      img {
        width: 100%;
        height: 46.9vw;
      }
      .content {
        background-color: #fff;
        padding: 4vw 2vw;
        font-size: 3.46vw;
        line-height: 6.5vw;
        p:nth-child(1) {
          margin-top: 20/7.5vw;

          font-size: 26/7.5vw;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 42/7.5vw;
        }
        p:nth-child(2) {
          overflow: hidden; //超出隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
          // 控制行数
          -webkit-line-clamp: 2; //超出两行隐藏
          -webkit-box-orient: vertical; // 从上到下垂直排列子元素
          margin-top: 20/7.5vw;

          font-size: 22/7.5vw;

          font-weight: 400;
          color: #666666;
          line-height: 36/7.5vw;
        }
        p:nth-child(3) {
          color: #999999;
          margin-top: 20/7.5vw;
        }
      }
    }
  }
}
</style>

